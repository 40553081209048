import { render, staticRenderFns } from "./feedback-consumer.vue?vue&type=template&id=66655d93&scoped=true"
import script from "./feedback-consumer.vue?vue&type=script&lang=js"
export * from "./feedback-consumer.vue?vue&type=script&lang=js"
import style0 from "./feedback-consumer.vue?vue&type=style&index=0&id=66655d93&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66655d93",
  null
  
)

export default component.exports